@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

.ag-cell,
.ag-full-width-row .ag-cell-wrapper.ag-row-group {
  /* disable all borders */
  --ag-borders: none !important;
  border: none !important;
  cursor: pointer;
}

.ag-theme-quartz {
  --ag-font-size: 18px !important;
}

@font-face {
  src: local('Aargau_Bold'), url('./fonts/Aargau Bold.ttf') format('truetype');
  font-family: "Aargau_Bold";
}

@font-face {
  src: local('Abuela'), url(./fonts/Abuela.ttf) format('truetype');
  font-family: "Abuela";
}

@font-face {
  src: local('AccoladeSerial_Bold'), url('./fonts/AccoladeSerial Bold.ttf') format('truetype');
  font-family: "AccoladeSerial_Bold";
}

@font-face {
  src: local('Apple_Chancery'), url('./fonts/Apple Chancery.ttf') format('truetype');
  font-family: "Apple_Chancery";
}

@font-face {
  src: local('Arial'), url(./fonts/Arial.ttf) format('truetype');
  font-family: "Arial";
}

@font-face {
  src: local('Black_Tuesday_Italic'), url('./fonts/Black Tuesday Italic.ttf') format('truetype');
  font-family: "Black_Tuesday_Italic";
}

@font-face {
  src: local('Courier_PS_Bold'), url('./fonts/Courier PS Bold.ttf') format('truetype');
  font-family: "Courier_PS_Bold";
}

@font-face {
  src: local('Courier_PS_BoldItalic'), url('./fonts/Courier PS BoldItalic.ttf') format('truetype');
  font-family: "Courier_PS_BoldItalic";
}

@font-face {
  src: local('Cry_Uncial_Condensed'), url('./fonts/Cry Uncial Condensed.ttf') format('truetype');
  font-family: "Cry_Uncial_Condensed";
}

@font-face {
  src: local('Cuida'), url(./fonts/Cuida.ttf) format('truetype');
  font-family: "Cuida";
}

@font-face {
  src: local('Curacao_Regular'), url('./fonts/Curacao Regular.ttf') format('truetype');
  font-family: "Curacao_Regular";
}

@font-face {
  src: local('D690_Roman_Regular'), url('./fonts/D690 Roman Regular.ttf') format('truetype');
  font-family: "D690_Roman_Regular";
}

@font-face {
  src: local('Deco_Regular'), url('./fonts/Deco Regular.ttf') format('truetype');
  font-family: "Deco_Regular";
}

@font-face {
  src: local('Dekka_Dense_JL'), url('./fonts/Dekka Dense JL.ttf') format('truetype');
  font-family: "Dekka_Dense_JL";
}

@font-face {
  src: local('EdwardianScript'), url('./fonts/EdwardianScript.ttf') format('truetype');
  font-family: "EdwardianScript";
}

@font-face {
  src: local('Enigma_Scrawl_4_BRK'), url('./fonts/Enigma Scrawl 4 BRK.ttf') format('truetype');
  font-family: "Enigma_Scrawl_4_BRK";
}

@font-face {
  src: local('MelbourneSerial_Light_Regular'), url('./fonts/MelbourneSerial Light Regular.ttf') format('truetype');
  font-family: "MelbourneSerial_Light_Regular";
}

@font-face {
  src: local('MercedesSerial_Light_Regular'), url('./fonts/MercedesSerial Light Regular.ttf') format('truetype');
  font-family: "MercedesSerial_Light_Regular";
}

@font-face {
  src: local('Merida'), url(./fonts/Merida.ttf) format('truetype');
  font-family: "Merida";
}

@font-face {
  src: local('Mf_Wedding_Bells'), url('./fonts/Mf Wedding Bells.ttf') format('truetype');
  font-family: "Mf_Wedding_Bells";
}

@font-face {
  src: local('Pneumatics_Wide_BRK'), url('./fonts/Pneumatics Wide BRK.ttf') format('truetype');
  font-family: "Pneumatics_Wide_BRK";
}

@font-face {
  src: local('Prestige_Regular'), url('./fonts/Prestige Regular.ttf') format('truetype');
  font-family: "Prestige_Regular";
}

@font-face {
  src: local('PriamosSerial_Xbold_Regular'), url('./fonts/PriamosSerial Xbold Regular.ttf') format('truetype');
  font-family: "PriamosSerial_Xbold_Regular";
}

@font-face {
  src: local('PriamosSerial_Xlight_Regular'), url('./fonts/PriamosSerial Xlight Regular.ttf') format('truetype');
  font-family: "PriamosSerial_Xlight_Regular";
}

@font-face {
  src: local('PrimerPrint_Bold'), url('./fonts/PrimerPrint Bold.ttf') format('truetype');
  font-family: "PrimerPrint_Bold";
}

@font-face {
  src: local('Regra'), url(./fonts/Regra.ttf) format('truetype');
  font-family: "Regra";
}

@font-face {
  src: local('ReportRg_Regular'), url('./fonts/ReportRg Regular.ttf') format('truetype');
  font-family: "ReportRg_Regular";
}

@font-face {
  src: local('RiccioneSerial_Italic'), url('./fonts/RiccioneSerial Italic.ttf') format('truetype');
  font-family: "RiccioneSerial_Italic";
}

@font-face {
  src: local('Regular'), url('./fonts/RiccioneSerial Light Regular.ttf') format('truetype');
  font-family: "RiccioneSerial_Light_Regular";
}

@font-face {
  src: local('Roman_Regular'), url('./fonts/Roman Regular.ttf') format('truetype');
  font-family: "Roman_Regular";
}

@font-face {
  src: local('SF_Cartoonist_Hand'), url('./fonts/SF Cartoonist Hand.ttf') format('truetype');
  font-family: "SF_Cartoonist_Hand";
}

@font-face {
  src: local('SF_Cartoonist_Hand_Bold'), url('./fonts/SF Cartoonist Hand Bold.ttf') format('truetype');
  font-family: "SF_Cartoonist_Hand_Bold";
}

@font-face {
  src: local("Sans_Bold"), url('./fonts/Sans Bold.ttf') format('truetype');
  font-family: "Sans_Bold"
}